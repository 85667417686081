import {
  ROLE_DIGITAL_HEALTHCARE,
  ROLE_PATENT_SPECIALIST,
  ROLE_LOGISTIC_MANGER,
  ROLE_RECOMMENDED_PRODUCTS,
  ROLE_ACCOUNTANT,
  ROLE_STAFF,
} from 'App/constants/Roles';

export const CAT_PRODUCTS = 'products';
export const CAT_SIMILAR_PRODUCTS = 'similar-products';
export const CAT_COMPANIES = 'companies';
export const CAT_BRANDS = 'brands';
export const CAT_PATENT_RESEARCH = 'patent_research';
export const CAT_TRADEMARKS = 'trademarks';
export const CAT_CONTACTS = 'contacts';
export const CAT_COMPONENTS = 'components';
export const CAT_PATENTS = 'patents';
export const CAT_NEWS = 'news';
export const CAT_COMPANY_NEWS = 'company_news';
export const CAT_REPORTSARCHIVE = 'reportsarchive';
export const CAT_STANDING_REPORTS = 'standing_reports';
export const CAT_STANDING_REPORTS_SECTIONS = 'standing_report_sections';
export const CAT_FILES = 'files';
export const CAT_PRODUCT_ORDERS = 'orders';
export const CAT_TRACKING_INFORMATION = 'tracking_informations';
export const CAT_COURIER_DELIVERY_SERVICES = 'courier_delivery_services';
export const CAT_PROJECTS = 'projects';
export const CAT_AGENTS = 'agents';
export const CAT_RECOMMENDED_PRODUCTS = 'recommended_products';
export const CAT_ORDER_AGENTS = 'order_agents';
export const CAT_CUSTOMERS = 'customers';
export const CAT_BILLING_INFORMATION = 'billing_informations';
export const CAT_CONSUMABLE_VARIATIONS = 'consumable_variations';
export const CAT_TYPES = 'types';
export const CAT_SAMPLES = 'samples';
export const CAT_SAMPLE_CONDITIONS = 'sample_conditions';
export const CAT_FEATURES = 'features';
export const CAT_FEATURE_OPTIONS = 'feature_options';
export const CAT_ADDITIONAL_FIELD_NAMES = 'additional_field_names';
export const CAT_PATENT_LEGAL_EVENTS = 'patent_legal_events';

export const CAT_BRAND_STAFF_STATISTICS = 'brand_staff_statistics';
export const CAT_COMPANY_STAFF_STATISTICS = 'company_staff_statistics';
export const CAT_CONTACT_STAFF_STATISTICS = 'contact_staff_statistics';
export const CAT_PATENT_STAFF_STATISTICS = 'patent_staff_statistics';
export const CAT_PRODUCT_STAFF_STATISTICS = 'product_staff_statistics';
export const CAT_EVENT_STAFF_STATISTICS = 'event_staff_statistics';
export const CAT_COMPONENTS_STAFF_STATISTICS = 'component_staff_statistics';

export const CAT_BRAND_STAFF_ITEM_STATISTICS = 'brand_staff_item_statistics';
export const CAT_COMPANY_STAFF_ITEM_STATISTICS = 'company_staff_item_statistics';
export const CAT_CONTACT_STAFF_ITEM_STATISTICS = 'contact_staff_item_statistics';
export const CAT_PATENT_STAFF_ITEM_STATISTICS = 'patent_staff_item_statistics';
export const CAT_PRODUCT_STAFF_ITEM_STATISTICS = 'product_staff_item_statistics';
export const CAT_EVENT_STAFF_ITEM_STATISTICS = 'event_staff_item_statistics';
export const CAT_COMPONENTS_STAFF_ITEM_STATISTICS = 'component_staff_item_statistics';
export const CAT_CPC_CLASSIFICATION = 'patents_cpc_classification';
export const CAT_IPC_CLASSIFICATION = 'patents_ipc_classification';

export const CAT_COMPONENT_PACKAGES = 'component_packages';
export const CAT_COMPONENT_CATEGORIES = 'component_categories';
export const CAT_USERS = 'users';
export const CAT_RESEARCHES = 'research';
export const CAT_USERGROUPS = 'usergroups';
export const CAT_PROOF_REQUESTS = 'proof_requests';
export const CAT_NICE_CLASSES = 'nice_classes';
export const CAT_PATENT_CITATIONS = 'patent_citations';
export const CAT_PATENT_NOTES = 'patent_notes';
export const CAT_USER_FILTERS = 'user_filters';
export const CAT_PRODUCT_MARKETS = 'product_markets';
export const CAT_EVENTS = 'events';
export const CAT_ATTENDING_REQUESTS = 'attending_requests';
export const CAT_EVENT_TYPES = 'event_types';
export const CAT_EVENT_THEMES = 'event_themes';
export const FILE_GROUP_MODULE_PRODUCTS = 1;
export const FILE_GROUP_MODULE_COMPANY = 2;

export const SUB_PRODUCTS_VAPING_DEV = 'smoking-alternatives'; // Vaping device
export const SUB_PRODUCTS_DHW_DEV = 'digital-health-and-wellbeing-device'; // Digital Health and Wellbeing device
export const PRODUCTS_CLASSIFICATION = 'classification';
export const TEARDOWN_REPORTS = 'teardown-reports';
export const SUB_PRODUCTS_VAPING_DEV_ID = 1;
export const SUB_PRODUCTS_DHW_DEV_ID = 2;
export const SUB_PRODUCTS_TEARDOWN_REPORTS = 3;

export const SUB_PRODUCT_ORDERS = CAT_PRODUCT_ORDERS;
export const SUB_CUSTOMERS = CAT_CUSTOMERS;
export const SUB_PROJECTS = CAT_PROJECTS;
export const SUB_AGENTS = CAT_AGENTS;
export const SUB_COURIER_DELIVERY_SERVICES = CAT_COURIER_DELIVERY_SERVICES;

export const PRODUCTS_SUBCATEGORIES = [SUB_PRODUCTS_VAPING_DEV, SUB_PRODUCTS_DHW_DEV, TEARDOWN_REPORTS];
export const ORDERS_SUBCATEGORIES = [
  SUB_PRODUCT_ORDERS,
  SUB_CUSTOMERS,
  SUB_COURIER_DELIVERY_SERVICES,
  SUB_PROJECTS,
  SUB_AGENTS,
];

export const SUGGESTED_FILTER_TYPE = 'suggested';
export const COMMON_FILTER_TYPE = 'common';
export const PATENT_RESEARCH_FILTER_TYPE = 'patent_research';

export const USP_TYPE_ID = 36;

export const PRODUCTS_SUBCATEGORIES_MAP = {
  [SUB_PRODUCTS_VAPING_DEV]: {
    title: 'Smoking Alternatives',
    id: SUB_PRODUCTS_VAPING_DEV_ID,
  },
  [TEARDOWN_REPORTS]: {
    title: 'Teardown reports',
    id: SUB_PRODUCTS_VAPING_DEV_ID,
    parent: CAT_PRODUCTS,
  },
  [PRODUCTS_CLASSIFICATION]: {
    title: 'Classification',
    id: SUB_PRODUCTS_VAPING_DEV_ID,
    parent: CAT_PRODUCTS,
  },
  [SUB_PRODUCTS_DHW_DEV]: {
    hint: 'Digital Health and Wellbeing devices',
    title: 'DH&W devices',
    id: SUB_PRODUCTS_DHW_DEV_ID,
    requiredRoles: [ROLE_DIGITAL_HEALTHCARE],
    divider: true,
  },
};

export function singularizeCat(cat) {
  return (
    {
      [CAT_PRODUCTS]: 'product',
      [CAT_COMPANIES]: 'company',
      [CAT_BRANDS]: 'brand',
      [CAT_PATENT_RESEARCH]: 'patent_research',
      [CAT_TRADEMARKS]: 'trademark',
      [CAT_CONTACTS]: 'contact',
      [CAT_COMPONENTS]: 'component',
      [CAT_PATENTS]: 'patent',
      [CAT_NEWS]: 'news',
      [CAT_COMPANY_NEWS]: 'company news',
      [CAT_EVENTS]: 'event',
      [CAT_REPORTSARCHIVE]: 'reports archive',
      [CAT_STANDING_REPORTS]: 'standing report',
      [CAT_FILES]: 'file',
      [CAT_CONSUMABLE_VARIATIONS]: 'consumable variation',
      [CAT_TYPES]: 'type',
      [CAT_SAMPLES]: 'sample',
      [CAT_SAMPLE_CONDITIONS]: 'sample condition',
      [CAT_BRAND_STAFF_STATISTICS]: 'brand staff statistics',
      [CAT_COMPANY_STAFF_STATISTICS]: 'company staff statistics',
      [CAT_CONTACT_STAFF_STATISTICS]: 'contact staff statistics',
      [CAT_PATENT_STAFF_STATISTICS]: 'patent staff statistics',
      [CAT_PRODUCT_STAFF_STATISTICS]: 'product staff statistics',
      [CAT_EVENT_STAFF_STATISTICS]: 'event staff statistics',
      [CAT_COMPONENTS_STAFF_STATISTICS]: 'component staff statistics',
      [CAT_BRAND_STAFF_ITEM_STATISTICS]: 'brand staff item statistics',
      [CAT_COMPANY_STAFF_ITEM_STATISTICS]: 'company staff item statistics',
      [CAT_CONTACT_STAFF_ITEM_STATISTICS]: 'contact staff item statistics',
      [CAT_PATENT_STAFF_ITEM_STATISTICS]: 'patent staff item statistics',
      [CAT_PRODUCT_STAFF_ITEM_STATISTICS]: 'product staff item statistics',
      [CAT_EVENT_STAFF_ITEM_STATISTICS]: 'event staff item statistics',
      [CAT_COMPONENTS_STAFF_ITEM_STATISTICS]: 'component staff item statistics',
      [CAT_COMPONENT_PACKAGES]: 'component package',
      [CAT_COMPONENT_CATEGORIES]: 'component category',
      [CAT_USERS]: 'user',
      [CAT_RESEARCHES]: 'research',
      [CAT_USERGROUPS]: 'user group',
      [CAT_PRODUCT_ORDERS]: 'order',
      [CAT_CUSTOMERS]: 'customer',
      [CAT_COURIER_DELIVERY_SERVICES]: 'delivery service',
      [CAT_PROJECTS]: 'project',
      [CAT_AGENTS]: 'agent',
      [CAT_RECOMMENDED_PRODUCTS]: 'recommended product',
      [CAT_ORDER_AGENTS]: 'agent payments',
    }[cat] || cat
  );
}

export const categories = new Set([CAT_CONTACTS, CAT_PATENTS, CAT_SAMPLES]);

export const searchEntities = new Set([
  CAT_PRODUCTS,
  CAT_BRANDS,
  CAT_CONTACTS,
  CAT_COMPANIES,
  CAT_TRADEMARKS,
  CAT_PATENTS,
]);

export const cmsCategories = new Set([
  CAT_PRODUCTS,
  CAT_STANDING_REPORTS,
  CAT_COMPANIES,
  CAT_BRANDS,
  CAT_PATENT_RESEARCH,
  CAT_TRADEMARKS,
  CAT_CONTACTS,
  CAT_COMPONENTS,
  CAT_SAMPLES,
  CAT_FILES,
  CAT_NEWS,
  CAT_CONSUMABLE_VARIATIONS,
  CAT_TYPES,
  CAT_SAMPLE_CONDITIONS,
  CAT_FEATURES,
  CAT_EVENTS,
  CAT_EVENT_TYPES,
  CAT_EVENT_THEMES,
  CAT_USERS,
  CAT_RESEARCHES,
  CAT_PRODUCT_ORDERS,
  CAT_CUSTOMERS,
  CAT_PROJECTS,
  CAT_COURIER_DELIVERY_SERVICES,
  CAT_AGENTS,
  CAT_ORDER_AGENTS,
  CAT_RECOMMENDED_PRODUCTS,
  CAT_PRODUCT_ORDERS,
]);

export const CMS_TAB_PRODS_DRAFT = 'products_draft';
export const CMS_TAB_PRODS = CAT_PRODUCTS;
export const CMS_TAB_PRODUCT_ORDERS = CAT_PRODUCT_ORDERS;
export const CMS_TAB_CUSTOMERS = CAT_CUSTOMERS;
export const CMS_TAB_COURIER_DELIVERY_SERVICES = CAT_COURIER_DELIVERY_SERVICES;
export const CMS_TAB_PROJECTS = CAT_PROJECTS;
export const CMS_TAB_AGENTS = CAT_AGENTS;
export const CMS_TAB_RECOMMENDED_PRODUCTS = CAT_RECOMMENDED_PRODUCTS;
export const CMS_TAB_BRANDS = CAT_BRANDS;
export const CMS_TAB_PATENT_RESEARCH = 'patent_research';
export const CMS_TAB_COMPANIES = CAT_COMPANIES;
export const CMS_TAB_COMPANIES_DRAFT = 'companies_draft';
export const CMS_TAB_STANDING_REPORTS = CAT_STANDING_REPORTS;
export const CMS_TAB_FILES = CAT_FILES;
export const CMS_TAB_SAMPLES = CAT_SAMPLES;
export const CMS_TAB_REF_DATA = 'ref_data';
export const CMS_TAB_STATS = 'stats_reports';
export const CMS_TAB_COMPONENTS = CAT_COMPONENTS;
export const CMS_TAB_CONTACTS = CAT_CONTACTS;
export const CMS_TAB_TRADEMARKS = CAT_TRADEMARKS;
export const CMS_TAB_NEWS = CAT_NEWS;
export const CMS_TAB_EVENTS = CAT_EVENTS;
export const CMS_TAB_USERS = CAT_USERS;
export const CMS_TAB_RESEARCHES = CAT_RESEARCHES;
export const CMS_TAB_EVENT_TYPES = CAT_EVENTS;
export const CMS_TAB_EVENT_THEMES = CAT_EVENT_THEMES;
export const CMS_SUB_TAB_COMPONENTS_DRAFT = 'sub_components_draft';
export const CMS_SUB_TAB_COMPONENTS = 'sub_components';
export const CMS_SUB_TAB_EVENTS_THEMES = 'sub_event_themes';

export const CMS_TABS = [
  {
    id: CMS_TAB_PRODS_DRAFT,
    cat: null,
    title: 'Products Draft',
    filter: { draft: true },
    defSubTab: SUB_PRODUCTS_VAPING_DEV,
    editCat: CAT_PRODUCTS,
  },
  {
    id: CMS_TAB_PRODS,
    cat: null,
    title: 'Products',
    filter: { draft: false },
    defSubTab: SUB_PRODUCTS_VAPING_DEV,
    editCat: CAT_PRODUCTS,
  },
  {
    id: CMS_TAB_RECOMMENDED_PRODUCTS,
    cat: CAT_RECOMMENDED_PRODUCTS,
    title: 'Recommended Products',
    editCat: CAT_RECOMMENDED_PRODUCTS,
    requiredRoles: [ROLE_RECOMMENDED_PRODUCTS, ROLE_STAFF],
  },
  {
    id: CMS_TAB_PRODUCT_ORDERS,
    cat: null,
    defSubTab: SUB_PRODUCT_ORDERS,
    title: 'Orders',
    requiredRoles: [ROLE_LOGISTIC_MANGER, ROLE_ACCOUNTANT],
  },
  { id: CMS_TAB_BRANDS, cat: CAT_BRANDS, title: 'Brands' },
  {
    id: CMS_TAB_PATENT_RESEARCH,
    cat: CAT_PATENT_RESEARCH,
    title: 'Patent research',
    requiredRoles: [ROLE_PATENT_SPECIALIST],
  },
  {
    id: CMS_TAB_COMPANIES_DRAFT,
    cat: CAT_COMPANIES,
    filter: { draft: true },
    title: 'Companies Draft',
  },
  // { id: CMS_TAB_COMPANIES, cat: CAT_COMPANIES, title: 'Companies' },
  {
    id: null,
    cat: null,
    title: 'Teardown reports',
    isExternal: true,
    href: 'https://teardown-report.winglegroup.com/',
  },
  {
    id: CMS_TAB_STANDING_REPORTS,
    cat: CAT_STANDING_REPORTS,
    title: 'Standing Reports',
  },
  { id: CMS_TAB_RESEARCHES, cat: CAT_RESEARCHES, title: 'Research' },
  { id: CMS_TAB_FILES, cat: CAT_FILES, title: 'Files' },
  { id: CMS_TAB_SAMPLES, cat: CAT_SAMPLES, title: 'Samples', hidden: true },
  { id: CMS_TAB_NEWS, cat: CAT_NEWS, title: 'News' },
  { id: CMS_TAB_EVENTS, cat: CAT_EVENTS, title: 'Events', defSubTab: CMS_TAB_EVENT_THEMES },
  {
    id: CMS_TAB_REF_DATA,
    cat: null,
    title: 'Reference Data',
    defSubTab: CAT_CONSUMABLE_VARIATIONS,
  },
  {
    id: CMS_TAB_STATS,
    cat: null,
    title: 'Statistics reports',
    hideActions: true,
  },
  {
    id: CMS_TAB_COMPONENTS,
    cat: null,
    title: 'Components',
    defSubTab: CMS_SUB_TAB_COMPONENTS_DRAFT,
    editCat: CAT_COMPONENTS,
  },
  { id: CMS_TAB_CONTACTS, cat: CAT_CONTACTS, title: 'Contacts' },
  { id: CMS_TAB_TRADEMARKS, cat: CAT_TRADEMARKS, title: 'Trademarks' },
  {
    id: null,
    cat: null,
    title: 'Samples',
    children: [
      { id: CMS_TAB_SAMPLES, cat: CAT_SAMPLES, title: 'Samples' },
      {
        id: null,
        cat: null,
        title: 'External Samples',
        isExternal: true,
        href: 'https://storage.vape.museum/',
      },
    ],
  },
  { id: CMS_TAB_USERS, cat: CAT_USERS, title: 'Users' },
  {
    id: null,
    cat: null,
    title: 'Apps & Services',
    children: [
      {
        id: null,
        cat: null,
        title: 'Research app',
        isExternal: true,
        href: 'https://scholar-app.winglegroup.com',
      },
      {
        id: null,
        cat: null,
        title: 'News app',
        isExternal: true,
        href: 'https://news-app.winglegroup.com',
      },
      {
        id: null,
        cat: null,
        title: 'Patents app',
        isExternal: true,
        href: 'https://patent-app.winglegroup.com',
      },
      {
        id: null,
        cat: null,
        title: 'Trademarks app',
        isExternal: true,
        href: 'https://tm.winglegroup.com',
      },
      {
        id: null,
        cat: null,
        title: 'Image extractor',
        isExternal: true,
        href: 'https://images-extractor.winglegroup.com/doc',
      },
      {
        id: null,
        cat: null,
        title: 'Search by image',
        isExternal: true,
        href: 'https://image-based-product-search.winglegroup.com/doc',
      },
      {
        id: null,
        cat: null,
        title: 'Text-recognition',
        isExternal: true,
        href: 'https://text-recognition.winglegroup.com/doc',
      },
    ],
  },
];

const productSubTabs = (commonId, title, draft) => {
  return PRODUCTS_SUBCATEGORIES.map((cat) => {
    const { id, title, requiredRoles } = PRODUCTS_SUBCATEGORIES_MAP[cat];
    return {
      id: cat,
      cat: CAT_PRODUCTS,
      filter: { draft, 'category.id': id },
      title,
      requiredRoles,
      categoryId: id,
    };
  });
};

export const CMS_SUB_TABS = {
  [CMS_TAB_REF_DATA]: [
    {
      id: CAT_CONSUMABLE_VARIATIONS,
      cat: CAT_CONSUMABLE_VARIATIONS,
      title: 'Consumable Variations',
    },
    { id: CAT_TYPES, cat: CAT_TYPES, title: 'Product Types' },
    {
      id: CAT_SAMPLE_CONDITIONS,
      cat: CAT_SAMPLE_CONDITIONS,
      title: 'Samples Conditions',
    },
    {
      id: CAT_FEATURES,
      cat: CAT_FEATURES,
      title: 'Features',
    },
    {
      id: CAT_EVENT_TYPES,
      cat: CAT_EVENT_TYPES,
      title: 'Event Types',
    },
    {
      id: CAT_EVENT_THEMES,
      cat: CAT_EVENT_THEMES,
      title: 'Event Themes',
    },
  ],
  [CMS_TAB_PRODUCT_ORDERS]: [
    {
      id: SUB_PRODUCT_ORDERS,
      cat: CAT_PRODUCT_ORDERS,
      title: 'Orders',
      requiredRoles: [ROLE_LOGISTIC_MANGER, ROLE_ACCOUNTANT],
    },
    {
      id: SUB_CUSTOMERS,
      cat: CAT_CUSTOMERS,
      title: 'Customers',
      requiredRoles: [ROLE_LOGISTIC_MANGER, ROLE_ACCOUNTANT],
    },
    {
      id: SUB_COURIER_DELIVERY_SERVICES,
      cat: CAT_COURIER_DELIVERY_SERVICES,
      title: 'Delivery services',
      requiredRoles: [ROLE_LOGISTIC_MANGER, ROLE_ACCOUNTANT],
    },
    {
      id: SUB_PROJECTS,
      cat: CAT_PROJECTS,
      title: 'Projects',
      requiredRoles: [ROLE_LOGISTIC_MANGER, ROLE_ACCOUNTANT],
    },
    {
      id: SUB_AGENTS,
      cat: CAT_AGENTS,
      title: 'Agents',
      requiredRoles: [ROLE_LOGISTIC_MANGER, ROLE_ACCOUNTANT],
    },
  ],
  [CMS_TAB_COMPONENTS]: [
    {
      id: CMS_SUB_TAB_COMPONENTS_DRAFT,
      cat: CAT_COMPONENTS,
      title: 'Components Draft',
      filter: { draft: true },
    },
    {
      id: CMS_SUB_TAB_COMPONENTS,
      cat: CAT_COMPONENTS,
      title: 'Components',
      filter: { draft: false },
    },
  ],
  [CMS_TAB_PRODS_DRAFT]: productSubTabs(null, 'All Drafts', true),
  [CMS_TAB_PRODS]: productSubTabs(null, 'All Products', false),
};

export const subCatsMap = new Map(
  Object.entries(CMS_SUB_TABS).reduce((acc, [tab, subTabs]) => {
    for (const sub of subTabs) {
      acc.push([sub.cat, { tab, sub: sub.id }]);
    }
    return acc;
  }, [])
);

export const STATS_CATEGORIES_MAP = {
  [CAT_BRANDS]: {
    id: CAT_BRAND_STAFF_STATISTICS,
    title: 'Brands',
    itemCat: CAT_BRAND_STAFF_ITEM_STATISTICS,
  },
  [CAT_COMPANIES]: {
    id: CAT_COMPANY_STAFF_STATISTICS,
    title: 'Companies',
    itemCat: CAT_COMPANY_STAFF_ITEM_STATISTICS,
  },
  [CAT_COMPONENTS]: {
    id: CAT_COMPONENTS_STAFF_STATISTICS,
    title: 'Components',
    itemCat: CAT_COMPONENTS_STAFF_ITEM_STATISTICS,
  },
  [CAT_CONTACTS]: {
    id: CAT_CONTACT_STAFF_STATISTICS,
    title: 'Contacts',
    itemCat: CAT_CONTACT_STAFF_ITEM_STATISTICS,
  },
  [CAT_PATENTS]: {
    id: CAT_PATENT_STAFF_STATISTICS,
    title: 'Patents',
    itemCat: CAT_PATENT_STAFF_ITEM_STATISTICS,
  },
  [CAT_PRODUCTS]: {
    id: CAT_PRODUCT_STAFF_STATISTICS,
    title: 'Products',
    itemCat: CAT_PRODUCT_STAFF_ITEM_STATISTICS,
  },
  [CAT_EVENTS]: {
    id: CAT_EVENT_STAFF_STATISTICS,
    title: 'Events',
    itemCat: CAT_EVENT_STAFF_ITEM_STATISTICS,
  },
};

/**
 *
 * @type {Map<string, string>}
 */
export const CATS_PATH_MAP = new Map([
  [CAT_BRANDS, '/api/brands'],
  [CAT_BRAND_STAFF_STATISTICS, '/api/brand_staff_statistics'],
  [CAT_COMPANIES, '/api/companies'],
  [CAT_COMPANY_STAFF_STATISTICS, '/api/company_staff_statistics'],
  [CAT_COMPONENTS, '/api/components'],
  [CAT_COMPONENTS_STAFF_STATISTICS, '/api/component_staff_statistics'],
  [CAT_CONTACTS, '/api/contacts'],
  [CAT_CONTACT_STAFF_STATISTICS, '/api/contact_staff_statistics'],
  [CAT_FILES, '/api/files'],
  [CAT_NEWS, '/api/news'],
  [CAT_COMPANY_NEWS, '/api/news'],
  [CAT_PATENTS, '/api/patents'],
  [CAT_PATENT_STAFF_STATISTICS, '/api/patent_staff_statistics'],
  [CAT_PRODUCTS, '/api/products'],
  [CAT_PRODUCT_STAFF_STATISTICS, '/api/product_staff_statistics'],
  [CAT_EVENT_STAFF_STATISTICS, '/api/event_staff_statistics'],
  [CAT_STANDING_REPORTS, '/api/reports'],
  [CAT_TRADEMARKS, '/api/trademark_items'],
  [CAT_EVENTS, '/api/events'],
  [CAT_RESEARCHES, '/api/researches'],
]);

export const TRACK_SESSION_TIME_CATS = new Set([CAT_BRANDS, CAT_COMPANIES, CAT_COMPONENTS, CAT_CONTACTS, CAT_PRODUCTS]);

export const CACHED_DATA_MEDIA_RESOURCE_TYPES = 'mediaResourceTypes';
export const CACHED_DATA_MODULES = 'modules';
export const CACHED_DATA_STAFF = 'staff';
export const CACHED_DATA_STAFF_TRACKED = 'trackedStaff';
export const CACHED_DATA_PATENTS_AMOUNT = 'patentsAmount';
export const CACHED_DATA_CATEGORIES = 'categories';

export const PROPS_PRODUCTS = [
  'id',
  'name',
  'productionStatus',
  'defaultImage',
  'types',
  'draft',
  'appeared',
  'category',
  'launchRegion',
  'brands',
  'manufacturers',
  'numberOfReports',
  'marketCountries',
];

export const PROPS_TRADEMARKS = ['id', 'name', 'holder', 'holderCountry', 'defaultImage', 'niceClasses'];

export const NO_STANDING_REPORTS = [
  CAT_BRANDS,
  CAT_PATENTS,
  CAT_TRADEMARKS,
  CAT_CONTACTS,
  CAT_COMPANY_NEWS,
  CAT_STANDING_REPORTS,
];
