import { apiGet, apiPost, apiPatch, apiDelete } from '../../Dataman';
import { DEALS_PATHS } from './deals_paths';
import { createRequestBodyAdapter } from './adapters';
import Backend from 'App/Dataman-Backend';

const { apiUrlSuffix } = Backend.config;

export const dealsAPiAdapter = {
  getProductOrders: (params) => apiGet(DEALS_PATHS.deals + apiUrlSuffix, { params }),
  getProductOrder: (id, params) => apiGet(`${DEALS_PATHS.deals}/${id}`, { params }),
  createProductOrder: (data) => {
    let preparedData = { ...createRequestBodyAdapter(data) };
    delete preparedData.trackingInformation;
    return apiPost(DEALS_PATHS.deals, { data: preparedData });
  },
  updateProductOrderInformation: (id, data) => {
    let preparedData = { ...data };
    preparedData = updateDealAdapter(preparedData);
    delete preparedData.trackingInformation;
    return apiPatch(`${DEALS_PATHS.deals}/${id}`, { data: preparedData });
  },
  deleteProductOrder: (id) => apiDelete(`${DEALS_PATHS.deals}/${id}`),
};

function updateDealAdapter(requestBody) {
  return {
    ...requestBody,
    dealItems: (requestBody.dealItems || []).map((dealItem) => {
      const res = {
        name: dealItem.item.name,
        quantity: dealItem.quantity,
      };
      if (dealItem.item.wimsId) {
        res.wimsId = dealItem.item.wimsId;
      }
      return res;
    }),
  };
}
